import { secondsToTime } from "../server/components/utils"
import { h, render } from 'preact'
import moment from 'moment'
import { T100Logo } from "../server/components/t100Logo"

let formRankings = document.getElementById('form-rankings')

if(formRankings)
{
	let selects = Array.from(formRankings.getElementsByTagName("select"))
	selects.forEach( function(element, index) {
		element.addEventListener("change", (e) => {
			let btn = formRankings.querySelector("button[type=submit]")
			if(btn) btn.click()
		})
	});

	let radios = formRankings.querySelectorAll('input[type=radio]')
	radios.forEach( function(element, index) {
		element.addEventListener("change", (e) => {
			let btn = formRankings.querySelector("button[type=submit]")
			if(btn) btn.click()
		})
	})
}

const truncateMobile = document.querySelectorAll('.truncate-mobile')

if (window.innerWidth <= 768) {
	truncateMobile.forEach(el => {
	el.textContent = el.textContent.charAt(0) + "."
	})
} else {
	truncateMobile.forEach(el => {
	el.textContent = el.getAttribute('data-original-content')
	})
}


window.addEventListener('resize', () => {
  if (window.innerWidth <= 768) {
    truncateMobile.forEach(el => {
      el.textContent = el.textContent.charAt(0) + "."
    })
  } else {
    truncateMobile.forEach(el => {
      el.textContent = el.getAttribute('data-original-content')
    })
  }
})

let rankingsFilter = document.getElementById("form-rankings")

if(rankingsFilter)
{
	rankingsFilter.addEventListener('submit', (e) => {
		e.preventDefault()

		var kvpairs = []
		for(let e of rankingsFilter.elements)
		{
			if(e.type == "radio" && !e.checked) continue
			if(e.value == "" || e.name == "") continue
			kvpairs.push(encodeURIComponent(e.name) + "=" + encodeURIComponent(e.value))
		}

		var queryString = kvpairs.join("&")
		location = location.origin + location.pathname + (queryString != "" ? "?" + queryString : "")
	})
}


const t100WinnerDropdowns = document.querySelectorAll(".pto-world-rankings--t100 .t100-winner")
if (t100WinnerDropdowns) {
	for (const t100WinnerDropdown of t100WinnerDropdowns) {
		const control = t100WinnerDropdown.querySelector(".fa-caret-square-down")
		control.addEventListener("click", function() {
			t100WinnerDropdown.querySelector(".t100-winner__results").classList.toggle("show")
		})
	}
}

let expandDetails = document.getElementsByClassName("expand-details")
if (expandDetails) {
	for (let expand of expandDetails) {
		expand.addEventListener("click", function(e) {
			let id = e.target.dataset.id || e.target.parentNode.dataset.id
			let setid = e.target.dataset.setid || e.target.parentNode.dataset.setid
			let discipline = e.target.dataset.discipline || e.target.parentNode.dataset.discipline
			let division = e.target.dataset.division || e.target.parentNode.dataset.division
			let seasonId = e.target.dataset.seasonid || e.target.parentNode.dataset.seasonid
			let athleteId = e.target.dataset.athleteid || e.target.parentNode.dataset.athleteid
			let disciplineIndex = 3
			let disciplineName = "overall"

			if (discipline == "swim") {
				disciplineIndex = 0
				disciplineName = "swim"
			}
			else if (discipline == "bike") {
				disciplineIndex = 1
				disciplineName = "bike"
			}
			else if (discipline == "run") {
				disciplineIndex = 2
				disciplineName = "run"
			}

			division = division == "MPRO" ? "men" : "women"

			let domDetails = document.getElementById('details-' + id)
			const isT100Rankings = domDetails.classList.contains('t100-rankings') ? true : false
			if (!domDetails.__expanded) {
				fetch("/api/series")
					.then(data => data.json())
					.then(data => {
						let series = data.series
						
						if (isT100Rankings) {
							fetch(`/t100/ranking/${seasonId}/${athleteId}/details`)
								.then(data => data.json())
								.then(data => {
									const details = data.ranking

									if (!details) {
										return
									}
									
									domDetails.__expanded = true

									render(
										<div className='rank-details' key={'details_' + id}>
											<div className='rank-row title'>
												<div>Scoring Breakdown</div>
												<div className='red px-2'>Sum of {data.season.top_scoring_results_count} best eligible results</div>
											</div>
											<div className='rank-row head'>
												<div className='date'>Date</div>
												<div className='race'>Race</div>
												<div className='position'>#</div>
												<div className='time'>Time</div>
												<div className='points'>PTO Pts</div>
												<div className='points'>
													<img src='../../assets/t100-logo-white.svg' alt='t100 logo' />
												</div>
											</div>
											{ details.map(x => {
												const isRaceObject = x.race !== undefined
												const isSeriesId = isRaceObject ? x.race?.series_id !== undefined : x.series_id !== undefined		
												const alias = isSeriesId ? series.filter(s => s.id === x.series_id)[0].alias : ( series.filter(s => s.name == x.serie)[0]?.alias || x.alias )
		
												return(
													<div className='rank-row'>
														<div className='date'>{moment.utc(x.date).format("DD MMM YY")}</div>
														<a className='race' href={'/race/' + ( alias ) + '/' + x.race_year + '/results'}>{x.name}</a>
														<div className='position'>{x.rank}</div>
														<div className='time'>{secondsToTime(x[disciplineName])}</div>
														<div className='points red'>{x[disciplineName+"_points"]?.toFixed(2)}</div>
														<div className='points red'>
															{x.t100_points ? x.t100_points.toFixed() : "-"}
														</div>
													</div>
												)
											})}

											{data.won_championships && (
												<div class='hide-for-desktop'>
													<div className='rank-row head'>
														<div className='winner'>Winner</div>
													</div>

													<div className='rank-row won-championships'>
														{data.won_championships.map((championship, key) => (
															<T100Logo tier={championship.name === 'Wildcard' ? championship.name : ''} noAthleteText useImage />
														))}
													</div>
												</div>
											)}
										</div>
										, domDetails)
								})
						}
						else {
							fetch("/ranking/" + setid + "/" + id + "/details")
								.then(data => data.json())
								.then(data => {
									let details = JSON.parse(data.ranking.details)
									if (!details) {
										return
									}
									domDetails.__expanded = true

									let subtitle = ''
									if (details[disciplineIndex].type === 'best_sum') {
										subtitle = 'Sum of best' + details[disciplineIndex].best_sum.length
									}
									else if (details[disciplineIndex].type === 'best_avg') {
										subtitle = 'Sum of best' + (details[disciplineIndex].best_sum?.length ? details[disciplineIndex].best_sum.length : " 3")
									}
									else if (['best_avg_07_22', 'best_avg_08_22'].includes(details[disciplineIndex].type)) {
										subtitle = disciplineIndex === 3
											? 'Average of 3 best eligible results'
											: 'Average of 2 best eligible results'
									}
									else if (details[disciplineIndex].type === '2023_last_52w') {
										subtitle = 'Average of 3 best eligible results'
									}
									else if(details[disciplineIndex].type === '2021_pre_cc') {
										subtitle = details[disciplineIndex].results.length === 2
											? 'Average Best 2'
											: ''
									}
									let show2022Explainer = false

									if (details[disciplineIndex].type == 'best_sum') {
										render(
											<div className='rank-details' key={'details_' + id}>
												<div className='rank-row title'>
													<div>Scoring Breakdown</div><div className='red px-2'>{subtitle}</div>
												</div>
												<div className='rank-row head'>
													<div className='date'>Date</div>
													<div className='race'>Race</div>
													<div className='time'>Time</div>
													<div className='time'>AIT</div>
													<div className='points'>Points</div>
												</div>
												{ details[disciplineIndex].best_sum.map(x => {
													let alias
													const isRaceObject = x.race !== undefined
													const isSeriesId = isRaceObject ? x.race?.series_id !== undefined : x.series_id !== undefined

													if (isRaceObject) {
														alias = isSeriesId ? series.filter(s => s.id === x.race.series_id)[0].alias : ( series.filter(s => s.name == x.race.serie)[0]?.alias || x.race.alias )
													} else {
														alias = isSeriesId ? series.filter(s => s.id === x.series_id)[0].alias : ( series.filter(s => s.name == x.serie)[0]?.alias || x.alias )
													}

													return(
														<div className='rank-row'>
															<div className='date'>{moment.utc(x.date).format("DD MMM YY")}</div>
															<a className='race' href={'/race/' + ( alias ) + '/' + x.year + '/results'}>{x.name}</a>
															<div className='time'>{secondsToTime(x[disciplineName])}</div>
															<div className='time'>{x["ait" + (discipline ? discipline + "_" : "") + "_" + division]}</div>
															<div className='points red'>{x[disciplineName+"_points"].toFixed(2)}</div>
														</div>
													)
												})}
											</div>
											, domDetails)
									}
									else if(details[disciplineIndex].type == '2021_pre_cc') {
										render(
											<div className='rank-details' key={'details_' + id}>
												<div className='rank-row title'>
													<div>Scoring Breakdown</div><div className='red px-2'>{ subtitle }</div>
												</div>
												<div className='rank-row head'>
													<div className='date'>Date</div>
													<div className='race'>Race</div>
													<div className='time'>Time</div>
													<div className='time'>AIT</div>
													<div className='points'>Points</div>
												</div>
												{ details[disciplineIndex].results.map(y => {
													if (y.type == "race") {
														let alias
														let x = y.race
														const isRaceObject = x.race !== undefined
														const isSeriesId = isRaceObject ? x.race?.series_id !== undefined : x.series_id !== undefined
		
														if (isRaceObject) {
															alias = isSeriesId ? series.filter(s => s.id === x.race.series_id)[0].alias : ( series.filter(s => s.name == x.race.serie)[0]?.alias || x.race.alias )
														} else {
															alias = isSeriesId ? series.filter(s => s.id === x.series_id)[0].alias : ( series.filter(s => s.name == x.serie)[0]?.alias || x.alias )
														}

														return(
															<div className='rank-row'>
																<div className='date'>{x.date && moment.utc(x.date).format("DD MMM YY")}</div>
																<a className='race' href={'/race/' + alias + '/' + x.year + '/results'}>{x.name}</a>
																<div className='time'>{secondsToTime(x[disciplineName])}</div>
																<div className='time'>{x["ait" + (discipline ? discipline + "_" : "") + "_" + division]}</div>
																<div className='points red'>{y.score.toFixed(2)}</div>
															</div>
														)
													}
													else if (y.type == "bestavg") {
														return (<div className='group'>
															{ y.results.map(x => {
																let alias
																const isRaceObject = x.race !== undefined
																const isSeriesId = isRaceObject ? x.race?.series_id !== undefined : x.series_id !== undefined
				
																if (isRaceObject) {
																	alias = isSeriesId ? series.filter(s => s.id === x.race.series_id)[0].alias : ( series.filter(s => s.name == x.race.serie)[0]?.alias || x.race.alias )
																} else {
																	alias = isSeriesId ? series.filter(s => s.id === x.series_id)[0].alias : ( series.filter(s => s.name == x.serie)[0]?.alias || x.alias )
																}

																return(
																	<div className='rank-row text-grey'>
																		<div className='date'>{moment.utc(x.date).format("DD MMM YY")}</div>
																		<a className='race text-grey' href={'/race/' + alias + '/' + x.year + '/results'}>{x.name}</a>
																		<div className='time'>{secondsToTime(x[disciplineName])}</div>
																		<div className='time'>{x["ait" + (discipline ? discipline + "_" : "") + "_" + division]}</div>
																		<div className='points'>{x[disciplineName+"_points"].toFixed(2)}</div>
																	</div>
																)
															})}
															<div className='rank-row'>
																<div className='date'></div>
																<a className='race'>Average Best {y.results.length} 2019/2020 seasons</a>
																<div className='time'></div>
																<div className='time'></div>
																<div className='points red'>{y.score.toFixed(2)}</div>
															</div>
														</div>)
													}
												})}
											</div>
											, domDetails)
									}
									else {
										render(
											<div className='rank-details' key={'details_' + id}>
												<div className='rank-row title'>
													<div>Scoring Breakdown</div><div className='red px-2'>{subtitle}</div>
												</div>
												<div className='rank-row head'>
													<div className='date'>Date</div>
													<div className='race'>Race</div>
													<div className='time'>Time</div>
													<div className='time'>{ data.ranking.ranking_system_id === 2
														? 'Place'
														: 'AIT'}</div>
													<div className='points'>Points</div>
												</div>
												{ details[disciplineIndex].results.map(x => {
													let alias
													let points = x.race[disciplineName+"_points"].toFixed(2)
													const isRaceObject = x.race !== undefined
													const isSeriesId = isRaceObject ? x.race?.series_id !== undefined : x.series_id !== undefined

													if (isRaceObject) {
														alias = isSeriesId ? series.filter(s => s.id === x.race.series_id)[0].alias : ( series.filter(s => s.name == x.race.serie)[0]?.alias || x.race.alias )
													} else {
														alias = isSeriesId ? series.filter(s => s.id === x.series_id)[0].alias : ( series.filter(s => s.name == x.serie)[0]?.alias || x.alias )
													}
													
													if (data.ranking.ranking_system_id === 2 && x.race['year'] === 2022 && x.race['ranking_system_id'] === 2) {
														points += '*'
														show2022Explainer = true
													}
													return(
														<div className='rank-row'>
															<div className='date'>{moment.utc(x.race.date).format("DD MMM YY")}</div>
															<a className='race' href={'/race/' + alias + '/' + x.race.year + '/results'}>{x.race.name + (x.adjust ? ` (${x.adjust})` : '')}</a>
															<div className='time'>{secondsToTime(x.race[disciplineName])}</div>
															<div className='time'>{ data.ranking.ranking_system_id === 2
																? disciplineName === 'overall'
																	? x.race['rank']
																	: x.race[disciplineName + '_rank']
																: x.race["ait" + (discipline ? discipline + "_" : "") + "_" + division]}</div>
															<div className='points red'>{points}</div>
														</div>
													)
												})}
												{show2022Explainer && <p className='explainer--2022-recalculated mt-3'>* Recalculated values from 2022 based on new ranking system.</p>}
											</div>
											, domDetails)
									}
								})
						}
						domDetails.classList.add('show')
					})
			}
			else {
				domDetails.__expanded = false
				domDetails.classList.remove('show')
			}

		})
	}
}

