import { h, render, Component, createRef, Fragment } from 'preact'
import { secondsToTime, getSeconds, AthletePic } from "../server/components/utils"
import moment from 'moment';

class AthleteView extends Component {

	container = (d) => {
		this.dom = d
	}

	shouldComponentUpdate()
	{
		return false
	}

	load = (link) => {
		this.dom.innerHTML = ""
		let that = this
		fetch("/athlete/" + link + "/info/embed")
		.then(data => data.json())
		.then(data => {
			that.dom.innerHTML = data.html
		})
	}

	render(props, state)
	{
		return (
			<div ref={this.container}>
			</div>
		)
	}
}

class LiveList extends Component {
	state = { races : [] }
	componentDidMount()
	{
		this.init()
	}
	componentWillUnmount()
	{
	}

	init = async () => {
		
		const list_fetch = await fetch("/api/live/list")
		const list_json = await list_fetch.json()

		this.setState({ races: list_json.races })
	}

	render(props, state)
	{
		return (
			<div class='d-flex flex-column align-items-center justify-content-center py-4 mb-4'>
				<h1 class='mb-4'>Live Races</h1>
				<div class='table live-list'>
					{ state.races.map(x => {
						return (
							<div class='race'>
								<div class='name'><a class='link-anchor' href={'/live/' + x.slug}>{x.name}</a></div>
								<div class='date'>{moment(x.date * 1000).format('LLLL')}</div>
							</div>
						)
					})}
				</div>
			</div>
		)
	}
}

class Live extends Component {
	state = { time: 0, display_athletes: [], tab: 0 }
	rtrt_ulr = "https://api.rtrt.me/events/"
	rtrt_auth = "?appid=52139b797871851e0800638e&token=2830AD3C9C8E4BEB5A74"

	athletes = []
	points = []

	max_split = -1
	race = null

	current_time = parseInt(Date.now() / 1000)

	componentDidMount()
	{
		this.timerUpdateInterval = setInterval(this.timerUpdate, 30 * 1000)
		this.init()
		document.addEventListener("visibilitychange", this.handleVisibilityChange, false)
	}
	componentWillUnmount()
	{
		clearInterval(this.timerUpdateInterval)
		document.removeEventListener("visibilitychange", this.handleVisibilityChange)
	}

	componentDidUpdate(prevProps, prevState, snapshot)
	{
	}

	handleVisibilityChange = (e) => {
		if(document.visibilityState)
		{
			this.timerUpdate()
		}
	}

	timerUpdate = (e) => {
		if(!this.race) return
		let now = parseInt(Date.now() / 1000)
		let startTime = parseInt(this.race.data.rtrt_event.earliestStartTime)
		if(now < startTime) return
		this.updateRTRTSplits(this.race.data.rtrt_event.name, this.race.data.rtrt_athletes, this.race.data.rtrt_points)
		this.updateRTRTAthletes(this.race.data.rtrt_event.name, this.race.data.rtrt_athletes)
	}

	init_popinfourl = (e) => {
		if(e) window.init_popinfourl(e)
	}

	init = async () => {
		
		const info_fetch = await fetch("/api/live/" + this.props.race)
		const info_json = await info_fetch.json()

		if(info_json.race)
		{
			let race = info_json.race
			this.athletes = info_json.athletes
			this.race = race
			for(let i in this.race.data.rtrt_points) this.race.data.rtrt_points[i].index = parseInt(i)
			this.updateRTRTSplits(this.race.data.rtrt_event.name, this.race.data.rtrt_athletes, this.race.data.rtrt_points)
			this.updateRTRTAthletes(this.race.data.rtrt_event.name, this.race.data.rtrt_athletes)
		}
	}

	updateRTRTAthletes_agt = 0
	updateRTRTAthletes = async (rtrt_name, rtrt_athletes) => {
		let athletes = []

		let pid_list = rtrt_athletes.map(x => x.pid)

		let bunch = 100
		while(1)
		{
			const profiles_fetch = await fetch(this.rtrt_ulr + rtrt_name + "/profiles/" + pid_list.join(",") + this.rtrt_auth + "&max=" + bunch + "&agt=" + this.updateRTRTAthletes_agt)
			const profiles_json = await profiles_fetch.json()

			if(profiles_json.error) break

			for(let e of profiles_json.list)
			{
				let athlete = rtrt_athletes.find(x => x.pid == e.pid)
				if(!athlete) continue
				if(e.pstatus) {
					athlete.pstatus = e.pstatus
					athlete.pstatus_note = e.pstatus_note
					athlete.pstatus_sub = e.pstatus_sub
				}
			}
			this.updateRTRTAthletes_agt = profiles_json.info.lasta		
			if(profiles_json.info.last < bunch) break
		}
	}

	updateRTRTSplits_agt = 0
	updateRTRTSplits = async (rtrt_name, athletes, points) => {
		let split_list = []
		let split_index = []
		for(let p of points)
		{
			split_index[p.name] = split_list.length
			split_list.push(p.name)
		}
		let pid_list = athletes.map(x => x.pid)
		
		let bunch = 500
		while(1)
		{
			const splits_fetch = await fetch(this.rtrt_ulr + rtrt_name + "/profiles/" + pid_list.join(",") + "/splits/" + split_list.join(",") + this.rtrt_auth + "&max=" + bunch + "&agt=" + this.updateRTRTSplits_agt)
			const splits_json = await splits_fetch.json()

			if(splits_json.error) break

			for(let split of splits_json.list)
			{
				let a = athletes.find(x => x.pid == split.pid)
				if(!a) continue
				if(!a.splits)
				{
					a.splits = Array(split_list.length).fill(null)
				}
				a.splits[split_index[split.point]] = split
			}

			this.updateRTRTSplits_agt = splits_json.info.lasta	
			if(splits_json.info.last < bunch) break
		}

		this.sortAthletes()
	}

	sortAthletes = () => {
		let split_list = []
		let split_index = []
		for(let p of this.race.data.rtrt_points)
		{
			split_index[p.name] = split_list.length
			split_list.push(p.name)
		}

		let display_athletes = [[],[]]
		for(let a of this.race.data.rtrt_athletes)
		{
			let da_index = a.division == 'MPRO' ? 0 : 1
			//if(a.division != 'MPRO') continue
			let da = {
				bib: parseInt(a.bib),
				dbid: a.dbid,
				country_iso: a.country_iso,
				name: a.name,
				pid: a.pid,
				pstatus: a.pstatus,
				lastSplit: -1,
				lastTime: -1,
				splits: Array(this.race.data.rtrt_points.length).fill(null),
				legs: [0,0,0,0,0],
				legspos: [0,0,0,0,0]
			}

			for(let index in a.splits)
			{	
				let split = a.splits[index]
				if(!split) continue
				let si = split_index[split.point]
				if(!si) continue

				//if(split.epochTime > this.current_time) continue
				let s = {
					point: split.point,
					epochTime: split.epochTime,
					i: split.i,
					u: split.u,
					legTime: getSeconds(split.legTime),
					time: getSeconds(split.time),
					pos: -1,
					deltaFirst: -1,
					deltaNext: -1,
				}
				if(s.point == "SWIM") da.legs[0] = s.legTime
				if(s.point == "T1") da.legs[1] = s.legTime
				if(s.point == "BIKE") da.legs[2] = s.legTime
				if(s.point == "T2") da.legs[3] = s.legTime
				if(s.point == "FINISH") da.legs[4] = s.legTime

				da.splits[si] = s
				da.lastSplit = index
				da.lastTime = s.time
				if(si > this.max_split) this.max_split = si
			}
			display_athletes[da_index].push(da)
		}


		for(let da_index = 0; da_index < 2; da_index++)
		{

			for(let i = 0; i < 5; i++)
			{
				display_athletes[da_index].sort((a,b) => { return a.legs[i] - b.legs[i] })
				let pos = 1
				for(let da of display_athletes[da_index])
				{
					if(da.legs[i] == 0) continue
					da.legspos[i] = pos
					pos += 1
				}
			}

			for(let p_index in this.race.data.rtrt_points)
			{
				let rtrt_points = this.race.data.rtrt_points
				display_athletes[da_index].sort((a,b) => { 
					if(a.splits[p_index])
					{
						if(b.splits[p_index])
						{
							return a.splits[p_index].time - b.splits[p_index].time 
						}
						return -1
					}
					return 1
				})
				let pos = 1
				let prevTime = display_athletes[da_index].length && display_athletes[da_index][0].splits[p_index] && display_athletes[da_index][0].splits[p_index].time
				for(let da of display_athletes[da_index])
				{
					if(!da.splits[p_index]) break
					da.splits[p_index].pos = pos
					da.splits[p_index].deltapos = 0
					let prevSplitTime = 0
					let prevSplitTimeFirst = 0
					let cur = p_index - 1
					while(cur >= 0) {
						if(da.splits[cur] && rtrt_points[cur].isStart != 1)
						{
							da.splits[p_index].deltapos = da.splits[cur].pos - da.splits[p_index].pos
							prevSplitTimeFirst = prevSplitTime = da.splits[cur].time
							break
						}
						cur -= 1
					}
					da.splits[p_index].deltaNext = da.splits[p_index].time - prevTime
					da.splits[p_index].deltaFirst = da.splits[p_index].time - display_athletes[da_index][0].splits[p_index].time

					if(pos != 1)
					{
						let cur = p_index - 1
						while(cur >= 0) {
							if(display_athletes[da_index][pos - 2].splits[cur] && rtrt_points[cur].isStart != 1)
							{
								prevSplitTime -= display_athletes[da_index][pos - 2].splits[cur].time
								break
							}
							cur -= 1
						}
						cur = p_index - 1
						while(cur >= 0) {
							if(display_athletes[da_index][0].splits[cur] && rtrt_points[cur].isStart != 1)
							{
								prevSplitTimeFirst -= display_athletes[da_index][0].splits[cur].time
								break
							}
							cur -= 1
						}
					}
					else prevSplitTimeFirst = 0

					da.splits[p_index].deltaNextPrevSplit = prevSplitTime
					da.splits[p_index].deltaFirstPrevSplit = prevSplitTimeFirst

					if(rtrt_points[p_index].isStart == 1)
					{
						da.splits[p_index].pos = 0
						da.splits[p_index].deltapos = 0
					}


					prevTime = da.splits[p_index].time
					pos += 1
				}
			}
			display_athletes[da_index].sort((a,b) => { return a.lastSplit == b.lastSplit ? (a.lastTime == b.lastTime ? a.bib - b.bib : a.lastTime - b.lastTime) : b.lastSplit - a.lastSplit })
		}
		//console.log(display_athletes)


		this.setState({ display_athletes: display_athletes })
	}

	changeTime = (e) => {
		this.current_time = e.target.value
		this.sortAthletes()
		this.setState({ time: this.current_time })
	}

	ontab = (e) => {
		let tab = e.target.dataset.tab
		this.setState({ tab: tab })
	}

	athleteView = (d) => { this.athleteViewDom = d }
	athleteClick = (e) => {
		let athlete = e.target.closest(".athlete")
		this.athleteViewDom.load(athlete.dataset.link)
		document.body.classList.add("noscroll")
		this.setState({ showathlete: true })
	}
	closeathlete = (e) => {
		let container = e.target.closest(".athlete-modal-container")
		let close = e.target.closest(".close-modal")
		if(container != e.target && close != e.target) return;
		document.body.classList.remove("noscroll")
		this.setState({ showathlete: false })
	}

	render(props, state)
	{
		if(!this.race) return
		let prevSplitIndex = -2

		let swim_point = this.race.data.rtrt_points.find(x => x.name == "SWIM")

		return(
			<Fragment>
				<div class='live-container'>
					<h2 class='mb-2'>{this.race.data.rtrt_event.shortName}</h2>
					<h4 class='mb-1'>{this.race.data.rtrt_event.loc.desc}</h4>
					<h5 class='mb-1'>{moment(this.race.data.rtrt_event.earliestStartTime * 1000).format('LLLL')}</h5>
					<div class='d-none'>{moment(this.current_time * 1000).format('LLLL')}</div>
					<input class='d-none' type='range' min='1619873400' max='1619893400' onInput={this.changeTime} value={this.current_time}/>
					<div class='tabs mt-2'>
						<div class='tab' onClick={this.ontab} data-tab={0} selected={state.tab == 0}>Men</div>
						<div class='tab' onClick={this.ontab} data-tab={1} selected={state.tab == 1}>Women</div>
					</div>

					<div class='live'>
						{ state.display_athletes[state.tab].map(y => { 
							//if(y.lastSplit == -1) return
							if(prevSplitIndex == y.lastSplit) return
							prevSplitIndex = y.lastSplit

							if(prevSplitIndex >= 0)
							{
								let currenPoint = this.race.data.rtrt_points[prevSplitIndex]
								let refAthlete = null
								return(
									<Fragment>
										<div class='group-point'>
											<div class='group-sticky'>
												<div class='group-point-info'>
													<div class='h3'>{currenPoint.label}</div>
													<div>
														<span class='move-up'>Gaining Time <i class="fas fa-long-arrow-alt-up"></i></span> <span class='move-down ml-2'>Losing Time <i class="fas fa-long-arrow-alt-down"></i></span></div>
													</div>

												<div class='athlete head'>
													<div class='top'>
														<div class='pos'></div>
														<div class='pic'></div>
														<div class='group-info'>
															<div class='name-splits'>
																<div class='name'></div>
																<div class='splits f12'>
																	<div class='time'>Time</div>
																	<div class='time'>to First</div>
																	<div class='time'>to Next</div>
																</div>
															</div>
														</div>
													</div>
													<div class='times'><div class='swim'></div></div>
												</div>
											</div>
											

											{state.display_athletes[state.tab].map(x => {
												if(x.lastSplit != prevSplitIndex) return
											
												let athlete = this.athletes.find(z => z.id == x.dbid)


												let lastsplit = x.splits[x.lastSplit]
												let lastpoint = this.points[x.lastSplit]
												let delta = refAthlete ? x.lastTime - refAthlete.lastTime : -1
												refAthlete = x

												let deltaPrev = lastsplit.deltaNext - lastsplit.deltaNextPrevSplit
												let deltaFirstPrev = lastsplit.deltaFirst - lastsplit.deltaFirstPrevSplit

												return(
													<div class='athlete' onClick={this.athleteClick} data-link={athlete && athlete.link}>
														<div class='top'>
															<div class='pos headline h4'>
																
																{ !x.pstatus && lastsplit.pos != 0 && lastsplit.pos}
																{ !x.pstatus && lastsplit.deltapos != 0 ?
																	( lastsplit.deltapos > 0 ? <div class='move-up'>{lastsplit.deltapos} <i class="fas fa-long-arrow-alt-up"></i></div>
																		: <div class='move-down'>{-lastsplit.deltapos} <i class="fas fa-long-arrow-alt-down"></i></div>
																	) : ""
																}
																{ x.pstatus && <div class='f12'>{x.pstatus}</div>}
															</div>
															<div class='pic'><AthletePic pic={athlete && athlete.pic} link={athlete && athlete.link} nolazy/></div>
															<div class='group-info'>
																<div class='name-splits'>
																	<div class='name'>
																		{ athlete && 
																			<div class='name-box' swimpos={x.legspos[0]} bikepos={x.legspos[2]} runpos={x.legspos[4]}>
																				<span class={'mr-1 flag-icon flag-icon-' + athlete.country.toLowerCase()}></span>
																				<a ref={this.init_popinfourl} _data-popinfourl={'/athlete/' + athlete.link + '/info'} _href={'/athlete/' + athlete.link}>
																					<span class='text-uppercase'>{athlete.first}</span>
																					<span class='headline ml-1'>{athlete.last}</span>
																				</a>
																			</div> 
																		}
																		{ !athlete && x.name }
																	</div>
																	<div class='splits'>
																		<div class='time font-weight-bold'>
																			{secondsToTime(lastsplit.time)}
																		</div>
																		<div class='time'>
																			{ deltaFirstPrev != 0 && lastsplit.deltaFirst != 0 ?
																				( deltaFirstPrev < 0 ? <div class='move-up'>+{secondsToTime(lastsplit.deltaFirst, true)} <i class="fas fa-long-arrow-alt-up"></i></div>
																					: <div class='move-down'>+{secondsToTime(lastsplit.deltaFirst, true)} <i class="fas fa-long-arrow-alt-down"></i></div>
																				) : ""
																			}
																		</div>
																		<div class='time'>
																			{ deltaPrev != 0 && lastsplit.deltaNext != 0 ?
																				( deltaPrev < 0 ? <div class='move-up'>+{secondsToTime(lastsplit.deltaNext, true)} <i class="fas fa-long-arrow-alt-up"></i></div>
																					: <div class='move-down'>+{secondsToTime(lastsplit.deltaNext, true)} <i class="fas fa-long-arrow-alt-down"></i></div>
																				) : ""
																			}
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class='times'>
															<div class='swim' pos={x.legspos[0]}>{x.legs[0] != 0 && <Fragment><i class="fas fa-swimmer"></i><span><span>{secondsToTime(x.legs[0])}</span><span class='legpos'>({x.legspos[0]})</span></span></Fragment>}</div>
															<div class='bike' pos={x.legspos[2]}>{x.legs[2] != 0 && <Fragment><i class="fas fa-biking"></i><span><span>{secondsToTime(x.legs[2])}</span><span class='legpos'>({x.legspos[2]})</span></span></Fragment>}</div>
															<div class='run' pos={x.legspos[4]}>{x.legs[4] != 0 && <Fragment><i class="fas fa-running"></i><span><span>{secondsToTime(x.legs[4])}</span><span class='legpos'>({x.legspos[4]})</span></span></Fragment>}</div>
														</div>
													</div>
												)
											})}
										</div>
									</Fragment>
								)
							}
							else
							{
								return(
									<Fragment>
										<div class='group-point'>
											<div class='group-point-info h3'>Waiting to Start</div>

											{state.display_athletes[state.tab].map(x => {
												if(x.lastSplit != prevSplitIndex) return
											
												let athlete = this.athletes.find(z => z.id == x.dbid)

												return(
													<div class='athlete'>
														<div class='top'>
															<div class='pic'><AthletePic pic={athlete && athlete.pic} link={athlete && athlete.link} nolazy/></div>
															<div class='name'>
																{ athlete && 
																	<div class='name-box' swimpos={x.legspos[0]} bikepos={x.legspos[2]} runpos={x.legspos[4]}>
																		<span class={'mr-1 flag-icon flag-icon-' + athlete.country.toLowerCase()}></span>
																		<a ref={this.init_popinfourl} data-popinfourl={'/athlete/' + athlete.link + '/info'} href={'/athlete/' + athlete.link}>
																			<span class='text-uppercase'>{athlete.first}</span>
																			<span class='headline ml-1'>{athlete.last}</span>
																		</a>
																	</div> 
																}
																{ !athlete && <span class='ml-1'>{x.name}</span> }
															</div>
														</div>
													</div>
												)
											})}
										</div>
									</Fragment>
								)
							}

						})}
					</div>
				</div>
				<div class='athlete-modal-container' show={state.showathlete} onClick={this.closeathlete}>
					<div class='athlete-modal-header'>
						<i class="fas fa-times close-modal" onClick={this.closeathlete}></i>
					</div>
					<div class='athlete-modal'>
						<AthleteView ref={this.athleteView}/>
					</div>
				</div>
			</Fragment>
		)
	}
}

class Login extends Component {
	login = () => {
		location.href = window.appData.oauth_host + '/authorize?redirect_uri=' + location.origin + "/authorize_callback&state=live" + '&client_id=' + window.appData.oauth_client_id
	}

	render(props, state)
	{
		return (
			<div class='d-flex flex-column align-items-center justify-content-center py-4 mb-4'>
				<h1 class='mb-4'>Live Races</h1>
				<div class='py-4 d-flex flex-column align-items-center text-center'>
					<div class='mb-4'>You need to be logged in to access the live races.</div>
					<button class='btn btn-primary' onClick={this.login}>Log in</button>
				</div>
			</div>
		)
	}
}

let live = document.getElementById('live')
if(live)
{
	fetch("/api/user/me")
	.then((r) => r.json())
	.then((d) => {
		if(d.session)
		{
			let race = live.dataset.race
			if(race)
			{	
				render(<Live race={race}/>, live)
			}
			else
			{
				render(<LiveList/>, live)
			}
		}
		else
		{
			render(<Login/>, live)
		}
	})
}

window.__get_race_info = async (rtrt_name) => {
	let rtrt_ulr = "https://api.rtrt.me/events/"
	let rtrt_auth = "?appid=52139b797871851e0800638e&token=2830AD3C9C8E4BEB5A74"

	const event_fetch = await fetch(rtrt_ulr + rtrt_name + rtrt_auth)
	const event_json = await event_fetch.json()

	let race = {}

	race.rtrt_event = event_json


	let points = []
	let bunch = 200
	let start = 1
	while(1)
	{
		const points_fetch = await fetch(rtrt_ulr + rtrt_name + "/points" + rtrt_auth + "&max=" + bunch + "&start=" + start)
		const points_json = await points_fetch.json()
		if(points_json.error) break
		if(points_json.info.first == points_json.info.last) break
		for(let e of points_json.list)
		{
			let s = {
				name: e.name,
				label: e.label,
				miles: e.miles,
				km: e.km 
			}
			if(e.isStart == 1) s.isStart = 1
			if(e.isFinish == 1) s.isFinish = 1
			points.push(s)
		}
		start = parseInt(points_json.info.last) + 1
	}
	race.rtrt_points = points


	let athletes = []
	start = 0
	while(1)
	{
		const profiles_fetch = await fetch(rtrt_ulr + rtrt_name + "/profiles" + rtrt_auth + "&max=" + bunch + "&agt=" + start)
		const profiles_json = await profiles_fetch.json()

		if(profiles_json.error) break
		if(profiles_json.info.first == profiles_json.info.last) break

		for(let e of profiles_json.list)
		{
			if(e.class == "relay") continue
			if(e.race.toUpperCase().indexOf("PRO") == -1 && e.race.toUpperCase().indexOf("ELITE") == -1) continue
			e.division = e.sex.toUpperCase() + "PRO"
			athletes.push({
				bib: e.bib,
				country_iso: e.country_iso,
				fname: e.fname,
				lname: e.lname,
				name: e.name,
				pid: e.pid,
				division: e.division,
			})
		}

		start = profiles_json.info.lasta		
	}


	for(let a of athletes)
	{
		//console.log(a)
		const search_fetch = await fetch("/api/search/" + a.name)
		const search_json = await search_fetch.json()
		if(search_json.results.length == 1) a.dbid = search_json.results[0].id
	}


	race.rtrt_athletes = athletes

	return JSON.stringify(race, null, "\t")
}



