const tooltipButtons = document.querySelectorAll('.tooltip-button');

// Function to hide all tooltips
function hideAllTooltips() {
	tooltipButtons.forEach((button) => {
		const tooltip = document.getElementById(button.getAttribute('popovertarget'));
		tooltip.classList.add('hidden');
	});
}

tooltipButtons.forEach((button) => {
	const tooltip = document.getElementById(button.getAttribute('popovertarget'));
	tooltip.classList.add('hidden');

	button.addEventListener('click', (event) => {
		event.stopPropagation(); // Prevent the click from bubbling up to the document
		hideAllTooltips(); // Hide all other tooltips
		tooltip.classList.toggle('hidden'); // Toggle the clicked tooltip
	});
});

document.addEventListener('click', (event) => {
	// Check if the click is outside any tooltip element
	let clickInsideTooltip = false;
	tooltipButtons.forEach((button) => {
		const tooltip = document.getElementById(button.getAttribute('popovertarget'));
		if (tooltip.contains(event.target)) {
			clickInsideTooltip = true;
		}
	});

	if (!clickInsideTooltip) {
		hideAllTooltips();
	}
});
