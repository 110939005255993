import '/client/search'
import '/client/modal'
import '/client/table-sort'
import '/client/pro-race-calendar'
import '/client/auth'
import '/client/admin'
import '/client/race'
import '/client/rankings'
import '/client/athletes'
import '/client/stats'
import '/client/head-to-head'
import '/client/news'
import '/client/instagram'
import '/client/page'
import '/client/scrollx'
import '/client/broadcast'
import '/client/select'
import '/client/fastest'
import '/client/navigation'
import '/client/youtube'
import '/client/results'
import '/client/collinscup'
import '/client/video-results'
import '/client/live'
import '/client/activecampaign'
import '/client/fantasy'
import '/client/media'
import '/client/vod'
import '/client/tooltip'

import Cookies from 'js-cookie'

/*window.onpageshow = function(e) {
	if (e.persisted) {
		window.location.reload();
	}
}*/

if('serviceWorker' in navigator){
	navigator.serviceWorker.register('/sw.js')
	/*.then(reg => console.log('service worker registered', reg))
	.catch(err => console.log('service worker not registered', err));*/
}


window.addEventListener('message', (message) => {
	//console.log("message", message, message.type, message.data)
	/*if(message.data.type == "pto-auth")
	{
		Cookies.set('pto_session_token', message.data.session_token, { expires: 365 })
		location.reload()
	}*/
}, false)

window.addEventListener('load', function(e) {
	let contentFade = document.getElementById('content-fade')
	if(contentFade)
	{
		contentFade.classList.remove('hide')	
	}
	if(window.parent != window) window.parent.postMessage({ type: "size", href: location.href, width: document.body.offsetWidth, height: document.body.offsetHeight }, '*')
})

setTimeout(
	function() {
		let contentFade = document.getElementById('content-fade')
		if(contentFade)
		{
			contentFade.classList.remove('hide')	
		}
	}, 1000)



let genTime = document.getElementById('gen-time')
if(genTime)
{
	if(window.appData.gen_time_hit != "")
	{
		genTime.innerHTML = window.appData.gen_time_hit.toFixed(4) + "ms - HIT"
	}
	else
	{
		genTime.innerHTML = window.appData.gen_time_miss.toFixed(1) + "ms - MISS"
	}
	//console.log(window.appData)	
}

let beyondHumanWatch = document.getElementById('beyond-human-watch')
function updateBeyondHuman()
{
	let diff =  1629907200 - parseInt(new Date().getTime() / 1000)

	if(diff < 0)
	{
		beyondHumanWatch.innerHTML = `<a href="https://thecollinscup.com/"><div>Watch the Collins Cup Opening Ceremony</b></div><div class='mt-2 pto-btn'>Click here to watch!</div></a>`
		//if(beyondHumanWatch) beyondHumanWatch.innerHTML = "" 
		return 
	}

	let days = parseInt((diff / (3600 * 24)))
	let hours = parseInt((diff / 3600) % 24)
	let minutes = parseInt((diff / 60) % 60)
	let seconds = parseInt(diff % 60)

	if(days < 10) days = "0" + days
	if(hours < 10) hours = "0" + hours
	if(minutes < 10) minutes = "0" + minutes
	if(seconds < 10) seconds = "0" + seconds

	if(beyondHumanWatch)
	{
		beyondHumanWatch.innerHTML = `<a href="https://thecollinscup.com/"><div>Countdown to <b>the Collins Cup Opening Ceremony</b></div><div>${days}<small class='ml-1 mr-2'>days</small>${hours}<small class='ml-1 mr-2'>hrs</small> ${minutes}<small class='ml-1 mr-2'>mins</small> ${seconds}<small class='ml-1 mr-2'>secs</small></div><div class='mt-2 pto-btn'>Click here to watch!</div></a>`
	}
}
/*if(beyondHumanWatch)
{
	updateBeyondHuman()
	setInterval(() => {
		updateBeyondHuman()
	}, 1000)
}*/

if(ResizeObserver)
{
	const resizeObserver = new ResizeObserver(entries => {
		if(window.parent != window) window.parent.postMessage({ type: "size", href: location.href, width: document.body.offsetWidth, height: document.body.offsetHeight }, '*')
		//console.log('Body height changed:', entries[0].target.clientHeight)
	})
	resizeObserver.observe(document.body)
}
else
{
	window.addEventListener('resize', function(e) {
		//console.log('resize')
		if(window.parent != window) window.parent.postMessage({ type: "size", href: location.href, width: document.body.offsetWidth, height: document.body.offsetHeight }, '*')
	})
}

let menuToggle = document.getElementById('menu-toggle')
let sideMenu = document.getElementById('side-menu')
let sideMenuBackground = document.getElementById('side-menu-background')
if(menuToggle)
{
	menuToggle.addEventListener("click", (e) => {
		menuToggle.classList.toggle("cross")
		sideMenu.classList.toggle("show")
		document.body.classList.toggle("noscroll")
	})

	if(sideMenuBackground)
	{
		sideMenuBackground.addEventListener("click", (e) => {
			menuToggle.click()
		})
	}
}

/*window.cookieconsent.initialise({
  "palette": {
    "popup": {
      "background": "#000"
    },
    "button": {
      "background": "#f1d600"
    }
  },
  "position": "bottom-right",
  "content": {
    "message": "ProTriathlonStats uses cookies to ensure you get the best experience."
  }
});
*/

let athletePopoverDuration = 250
let athletePopoverTimer = null
let athletePopover = document.getElementById('athlete-popover')
let athletePopoverStack = []

let all_as = Array.from(document.getElementsByTagName("a"))
all_as.forEach( function(element, index) {
	element.addEventListener("click", (e) => {

		if(athletePopoverTimer) 
		{
			clearTimeout(athletePopoverTimer)
			athletePopoverTimer = null
		}
	})
});

document.addEventListener('click', function(e) {
	if(document.body.classList.contains("embed"))
	{
		let href = null, d = e.target
		do {
			if(d.href) href = d.href
			d = d.parentElement
		} while(d && href == null)

		if(href)
		{
			window.open(href, '_blank')
			e.preventDefault()
		}
	}
})


window.addEventListener('pagehide', function(e) {
	if(athletePopover)
	{
		athletePopover.style.display = 'none'
		athletePopover.classList.remove("active")
	}

	let searchContainer = document.getElementById('search-container')
	if(searchContainer) searchContainer.classList.add("hide")

})

function hookCircleQuestion(d)
{
	Array.from(document.getElementsByClassName("question-circle")).forEach(function(b) {
		b.addEventListener('click', function(e) {
			let description = b.parentElement.getElementsByClassName("description")
			if(description.length)
			{
				description[0].classList.add("show")
				description[0].addEventListener('click', function(e) {
					e.target.classList.remove("show")
				}, { once: true })
			}
		})
	})
}

hookCircleQuestion(document)

function athletePopoverTimerFunction() {
	athletePopoverTimer = null
	let info = athletePopoverStack.shift()
	if(!info) return
	if(info.type == 'show')
	{
		fetch(info.target.dataset.popinfourl)
		.then(data => data.json())
		.then(data => {
			athletePopover.innerHTML = data.html
			athletePopover.classList.add("active")
			hookCircleQuestion(athletePopover)
		})

		let obj = info.target;
		let offsetLeft = 0, offsetTop = 0;
		do {
			if (!isNaN(obj.offsetLeft)) offsetLeft += obj.offsetLeft - obj.scrollLeft;
			if (!isNaN(obj.offsetTop)) offsetTop += obj.offsetTop + obj.scrollTop;
		} while(obj = obj.offsetParent);

		let transform = ""
		if(info.top) {
			athletePopover.style.top = "50%"
			athletePopover.style.left = "55%"
			athletePopover.style.transform = "translate(-50%, -50%)"
		} else {
			athletePopover.style.top = (offsetTop + info.target.offsetHeight + 0 ) + "px"

			if(info.left) {
				transform += " translateX(-100%)"
				athletePopover.style.left = (offsetLeft + info.target.offsetWidth + 16) + "px"
			} else {
				athletePopover.style.left = (offsetLeft - 16) + "px"
			}

			athletePopover.style.transform = transform
		} 
	}
	else if(info.type == 'hide')
	{
		athletePopover.classList.remove("active")
		//athletePopover.style.display = 'none'
	}
	if(athletePopoverStack.length)
	{
		athletePopoverTimer = setTimeout(athletePopoverTimerFunction, athletePopoverDuration)
	}
}

athletePopover.addEventListener('mouseenter', () => {
	if(athletePopoverTimer) 
	{
		clearTimeout(athletePopoverTimer)
		athletePopoverTimer = null
	}
})

athletePopover.addEventListener('mouseleave', () => {
	if(athletePopoverTimer) clearTimeout(athletePopoverTimer)
	athletePopoverTimer = setTimeout(athletePopoverTimerFunction, athletePopoverDuration)
})



window.init_popinfourl = (element) =>
{
	if(element.popinfourl_inited) return

	const showEvents = ['mouseenter']//, 'focus'];
	const hideEvents = ['mouseleave']//, 'blur'];

	showEvents.forEach(event => {
		element.addEventListener(event, (e) => {
			let centerY = document.documentElement.clientHeight / 2;
			let centerX = document.documentElement.clientWidth / 2;
			let info = { type: 'show', target: e.target, top: e.clientY > centerY, left: e.clientX > centerX }
			//console.log(info)
			athletePopoverStack = athletePopoverStack.filter(x => x.target != e.target)
			athletePopoverStack.push(info)
			if(athletePopoverTimer) clearTimeout(athletePopoverTimer)
			athletePopoverTimer = setTimeout(athletePopoverTimerFunction, athletePopoverDuration)
		});
	});

	hideEvents.forEach(event => {
		element.addEventListener(event, (e) => {
			let length = athletePopoverStack.length
			athletePopoverStack = athletePopoverStack.filter(x => x.target != e.target)
			//if(athletePopoverStack.length == length)
			{
				let info = { type: 'hide', target: e.target }
				athletePopoverStack.push(info)
			}
			if(athletePopoverTimer) clearTimeout(athletePopoverTimer)
			athletePopoverTimer = setTimeout(athletePopoverTimerFunction, athletePopoverDuration)
		});
	});

	element.popinfourl_inited = true
}

let athletePopInfos = Array.from(document.querySelectorAll("a[data-popinfourl]"))
athletePopInfos.forEach( function(element, index) {
	window.init_popinfourl(element)
})



// let all_navlinks = Array.from(document.getElementsByClassName("nav-link"))
// all_navlinks.forEach( function(element, index) {
// 	element.addEventListener("click", function(e) {
// 		e.preventDefault()
//
// 		let tab = document.getElementById(this.attributes.getNamedItem("href").nodeValue)
// 		let actives = tab.parentNode.getElementsByClassName("active")
// 		for(let i = 0; i < actives.length; i++)
// 		{
// 			actives[i].classList.remove("active")
// 		}
// 		tab.classList.add("active")
//
//
// 		actives = this.parentNode.getElementsByClassName("active")
// 		for(let i = 0; i < actives.length; i++)
// 		{
// 			actives[i].classList.remove("active")
// 		}
// 		this.classList.add("active")
//
// 	})
// });

document.addEventListener("DOMContentLoaded", function() {
	var lazyImages = [].slice.call(document.querySelectorAll("picture.lazy"));

	if ("IntersectionObserver" in window) {
		let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
			entries.forEach(function(entry) {
			if (entry.isIntersecting) {
				let lazyPicture = entry.target;
				for(let c of lazyPicture.children)
				{
					if(c.dataset.src) c.src = c.dataset.src
					if(c.dataset.srcset) c.srcset = c.dataset.srcset
				}
				lazyPicture.classList.remove("lazy")
				lazyImageObserver.unobserve(lazyPicture)
			}
		});
	});

	lazyImages.forEach(function(lazyImage) {
		lazyImageObserver.observe(lazyImage);
	})
} else {
}
})


